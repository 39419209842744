<template>
  <b-modal
    v-model="dialogState"
    size="lg"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    @show="resetForm"
  >
    <template v-slot:modal-title>
      <h5 class="mb-0 text-secondary">
        {{ $t("page.groups.newGroup") }}
      </h5>
    </template>
    <template v-slot:default>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <b-form>
          <form-input
            v-model="group.name"
            label="page.groups.createModal.nameLabel"
            :placeholder="$t('page.groups.createModal.namePlacehoder')"
            veeName="page.groups.createModal.nameLabel"
            :veeRules="{ required: true }"
          />
          <table class="mb-5">
            <tr>
              <td>
                <div class="mb-2 mr-3">
                  {{ $t("page.groups.createModal.comments") }}
                </div>
              </td>
              <td>
                <base-switcher
                  v-model="group.commentPermissions"
                  name="comments-switch"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="mb-2 mr-3">
                  {{ $t("page.groups.createModal.status") }}
                </div>
              </td>
              <td>
                <base-switcher v-model="group.status" name="status-switch" />
              </td>
            </tr>
          </table>

          <hr class="my-3 mx-n3" />
          <b-row class="no-gutters justify-content-end">
            <b-button variant="outline-dark" class="mr-3" @click="closeModal">
              {{ $t("button.cancel") }}
            </b-button>
            <b-button
              class="text-white"
              variant="primary"
              :disabled="invalid"
              @click="createGroup"
            >
              {{ $t("button.insert") }}
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import FormInput from "./../../../../console-components/BaseFormInput";
import BaseSwitcher from "./../Base/Switcher.vue";

export default {
  props: {
    state: { type: Boolean, default: false },
    callbackAfterCreate: { type: Function, default: () => {} }
  },
  components: { FormInput, BaseSwitcher },
  data() {
    return {
      group: {
        name: "",
        status: false,
        commentPermissions: false
      }
    };
  },
  computed: {
    dialogState: {
      get() {
        return this.state;
      },
      set() {
        this.closeModal();
      }
    }
  },
  methods: {
    resetForm() {
      this.group = {
        name: "",
        status: false,
        commentPermissions: false
      };
    },
    async createGroup() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("groups/createGroup", {
          payload: this.getGroupPayload()
        });
        await this.callbackAfterCreate();
        this.closeModal();
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    getGroupPayload() {
      const groupDetails = {
        name: this.group.name,
        status: this.group.status ? "active" : "inactive",
        commentPermissions: this.group.commentPermissions
      };
      return groupDetails;
    },
    closeModal() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped></style>
