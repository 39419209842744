<template>
  <div>
    <h3 class="mb-5 text-secondary">{{ $t("page.groups.title") }}</h3>
    <groups-filters></groups-filters>
    <div class="mb-5">
      <b-row class="no-gutters justify-content-between align-items-center mb-3">
        <b-col class="col-12">
          <b-button
            class="float-right mb-3"
            size="md"
            variant="secondary"
            @click="openCreateGroupModal"
            v-b-modal.create-group-modal
          >
            {{ $t("page.groups.newGroup") }}
          </b-button>
        </b-col>
      </b-row>
      <groups-table
        :groups="groups"
        :fields="fields"
        :sortBy.sync="sortBy"
        :sortDesc.sync="sortDesc"
        :retrieveDataCallback="retrieveGroupsCallback"
      />
      <create-group
        :state="createModalState"
        :callbackAfterCreate="retrieveGroupsCallback"
        @close="closeCreateGroupModal"
      />
    </div>
  </div>
</template>

<script>
import GroupsFilters from "../components/Groups/GroupFilters.vue";
import GroupsTable from "../components/Groups/GroupsTable.vue";
import CreateGroup from "../components/Groups/CreateGroup.vue";

export default {
  components: {
    GroupsFilters,
    GroupsTable,
    CreateGroup
  },
  data() {
    return {
      createModalState: false,

      fields: [
        { key: "name", label: "name", sort: true, thStyle: { width: "30%" } },
        { key: "status", label: "status", thStyle: { width: "15%" } },
        {
          key: "commentPermissions",
          label: "comment",
          thStyle: { width: "15%" }
        },
        {
          key: "membersCount",
          label: "users",
          sort: true,
          thStyle: { width: "15%" }
        },
        { key: "details", label: "", thStyle: { width: "25%" } }
      ],
      sortBy: "name",
      sortDesc: false
    };
  },
  computed: {
    groups() {
      return this.$store.getters["groups/getGroups"];
    }
  },
  methods: {
    retrieveGroupsCallback() {
      return this.$store.dispatch("groups/retrieveGroups");
    },
    openCreateGroupModal() {
      this.createModalState = true;
    },
    closeCreateGroupModal() {
      this.createModalState = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
